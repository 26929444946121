import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './Slider.css'
import { companyData } from '../../../constant/companyData';

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024
    },
    items: 3,
    partialVisibilityGutter: 0
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0
    },
    items: 1,
    partialVisibilityGutter: 10
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464
    },
    items: 2,
    partialVisibilityGutter: 0
  }
};

const data: any = companyData || {}
let location: any = window.location.pathname;
location = location.substring(1);
const  {Banner} : any = (location === null || location === undefined || location==='' ) ? data.property1 : data[location] 
console.log("window.location.pathnamewindow.location.pathname",Banner)
const Slider = () => {
  return (
    <>
      <div>
        <div id="photo" className='destination-scroll'></div>
        <Carousel className='custom-carousal-style' responsive={responsive}
        >
          {Banner && Banner?.map((item: any, index: any) => {
            return (
              <div key={index} className='carousal-image' style={{ backgroundImage: `url(${item})` }} />
              // , marginRight:'1.5%'
            )
          })}
        </Carousel>
      </div>
    </>
  );
};

export default Slider;
