import React, { useState, useContext, useEffect } from 'react'
import { MyContext } from '../../../App'
import { NavLink, BrowserRouter as Router, Route, useLocation } from 'react-router-dom'
import './Header.css'



const Header = () => {

  // const test=()=>{
  //   const helloElement = document.querySelector('#floor-plan-sec');
  //    console.log(helloElement,'test1');

  //   if (helloElement) {
  //     helloElement.scrollIntoView({
  //       behavior: 'smooth',
  //       block: 'start', 
  //       Align the top of the element with the top of the viewport
  //       inline: 'nearest', 
  //       Keep the element as close to the center as possible       
  //     });
  //     window.scrollBy(0, -59);
  //     console.log('test2');
  //   }


  // }
  // const {nav,setNav} :any = useContext(MyContext)

  // const test=()=>{
  //   document.querySelector('.hello').scrollIntoView({ 
  //     behavior: 'smooth' 
  //   });
  // }

  // useEffect(()=>{
  //   test()
  // },[])

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    console.log("dsfhajksdfh")
    setIsOpen(false);
  };

  const [active, setActive] = useState('photo')

  return (
    <>

      <div className='header-main d-none d-md-block'>
        <nav className="navbar fixed-top navbar-expand-lg hearder-bg pb-0">
          <div className="container">
            <a className="navbar-brand fs-22 fw-800 " href="#">
              <span style={{ color: '#9F7B4B' }}>Prop</span>Book
            </a>
            <button
              onClick={handleToggle}
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className={`collapse navbar-collapse justify-content-center ${isOpen ? 'show' : 'hide'}`} id="navbarNav">
              <ul className="navbar-nav gap-md-4">
                <li className="nav-item ">
                  <a className={`nav-link cool-link text-uppercase fs-14 fw-500 ${active === 'photo' ? 'active' : ''}`} aria-current="page" href="#photo" onClick={() => {
                    setActive('photo')
                    handleClose()
                  }}>
                    Photos
                  </a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link cool-link text-uppercase fs-14 fw-500 ${active === 'address-sec' ? 'active' : ''}`} href="#address-sec" onClick={() => {
                    setActive('address-sec')
                    handleClose()
                  }}>
                    Location
                  </a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link cool-link text-uppercase fs-14 fw-500 ${active === 'floor-plan-sec' ? 'active' : ''}`} href="#floor-plan-sec" onClick={() => {
                    setActive('floor-plan-sec')
                    handleClose()
                  }}>
                    Floor Plan
                  </a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link cool-link  text-uppercase fs-14 fw-500 ${active === 'virtual-tour' ? 'active' : ''}`} href="#virtual-tour" onClick={() => {
                    setActive('virtual-tour')
                    handleClose()
                  }}>
                    Virtual Tour
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div >
      <div className='empty-div'></div>



      {/* Mobile View Header  */}

      <div className='header-main d-block d-md-none'>
        <nav className="navbar fixed-top navbar-expand-lg hearder-bg">
          <div className="container px-0">
            <a className="navbar-brand fs-22 fw-800 w-100 text-center " href="#">
              <span style={{ color: '#9F7B4B' }}>Prop</span>Book
            </a>
            {/* <button
              onClick={handleToggle}
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button> */}
            {/* collapse */}
            <div className={` navbar-collapse justify-content-center ${isOpen ? 'show' : 'hide'}`} id="navbarNav">
              <ul className="d-flex ps-0 mx-0 mt-3 mb-0 border-bottom list-unstyled">
                <li className="nav-item nav-li-width ">
                  <a className={`nav-link cool-link mobile-nav-link  text-uppercase fs-12 fw-600 ${active === 'photo' ? 'active' : ''}`} aria-current="page" href="#photo" onClick={() => {
                    setActive('photo')
                    handleClose()
                  }}>
                    Photos
                  </a>
                </li>
                <li className="nav-item nav-li-width ">
                  <a className={`nav-link cool-link mobile-nav-link  text-uppercase fs-12 fw-600 ${active === 'address-sec' ? 'active' : ''}`} href="#address-sec" onClick={() => {
                    setActive('address-sec')
                    handleClose()
                  }}>
                    Location
                  </a>
                </li>
                <li className="nav-item nav-li-width ">
                  <a className={`nav-link cool-link mobile-nav-link  text-uppercase fs-12 fw-600 ${active === 'floor-plan-sec' ? 'active' : ''}`} href="#floor-plan-sec" onClick={() => {
                    setActive('floor-plan-sec')
                    handleClose()
                  }}>
                    Floor Plan
                  </a>
                </li>
                <li className="nav-item nav-li-width ">
                  <a className={`nav-link cool-link mobile-nav-link  text-uppercase fs-12 fw-600 ${active === 'virtual-tour' ? 'active' : ''}`} href="#virtual-tour" onClick={() => {
                    setActive('virtual-tour')
                    handleClose()
                  }}>
                    Virtual Tour
                  </a>
                </li>
              </ul>
            </div>
            {/* <div className='empty-div'></div> */}

          </div>
        </nav>
      </div >


    </>

  )
}

export default Header