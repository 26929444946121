import React, { Component, useRef } from 'react';
import ReactDOM, { useLocation } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './Virtual.css'
import { companyData } from '../../../constant/companyData'

// import Images from '../../../assets/Images'
// const { FloorImage } = Images
const Vertual = () => {
    const data: any = companyData
    const iframeRef: any = useRef(null);
    let location: any = window.location.pathname
    location = location.substring(1)
    const { VirtualHeading, CollectionLink }: any =  (location === null || location === undefined || location==='' ) ? data.property1 : data[location] 
    // console.log("location===",data[location])
    const playVideo = () => {
        const iframe = iframeRef.current;
        // Post a message to the iframe to trigger play
        // iframe.contentWindow.postMessage('play', '*');
        // console.log('Sent play message to the iframe content window',iframe);
    };

    return (
        <>
            <div id='virtual-tour' className='destination-scroll'></div>
            <section className='sec-divider container'>
                <div className='d-flex justify-content-between align-items-center mb-4'>
                    <h2 className='text-brown fs-22 fw-700 mobile-font-22 '>{VirtualHeading}</h2>
                    <a href={CollectionLink} target="_blank" className='view-btn' onClick={() => playVideo()}>
                        Full View
                    </a>
                </div>
                {/* <h2 className='text-brown fs-22 fw-800 mb-4'>{VirtualHeading}</h2> */}
                <div className="row">
                    <div className='col-md-12'>
                        <div className='virtual-img '>
                            <iframe
                                title="Virtual Tour"
                                ref={iframeRef}
                                width="100%"
                                height="100%"
                                src={CollectionLink}
                                frameBorder="0"
                                allowFullScreen
                                // sandbox="allow-scripts allow-same-origin allow-presentation"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}
export default Vertual
