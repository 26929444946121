import FloorImage from './chart.jpg'
import Whatsapp from './whatsapp.svg'
import Mail from './mail.svg'
import Phone from './phone.svg'
import House from './house.jpg'
import House2 from './house2.jpg'

const Images = {
    FloorImage,
    Whatsapp,
    Mail,
    Phone,
    House,
    House2
}

export default Images