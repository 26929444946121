import React, { Component, useState } from 'react';
import ReactDOM, { useLocation } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './Floor.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { companyData } from '../../../constant/companyData'

const Floor = () => {
    const [show, setShow] = useState(false);
    const data: any = companyData
    let location: any = window.location.pathname
    location = location.substring(1)
    const { FloorHeading, FloorPlanBanner }: any =  (location === null || location === undefined || location==='' ) ? data.property1 : data[location] 
    // console.log("location===",data[location])
    return (
        <>
            <div id='floor-plan-sec' className='destination-scroll'></div>
            <section className='container sec-divider '>
                <div className='d-flex justify-content-between align-items-center mb-4'>
                    <h2 className='text-brown fs-22 fw-700 mobile-font-22 '>{FloorHeading}</h2>
                    <button className='view-btn shadow-none' onClick={() => setShow(true)} >View Plan</button>
                </div>
                <div className="row">
                    <div className='col-md-12'>
                        <>{console.log("FloorPlanBanner[0]",FloorPlanBanner[0])}</>
                        <div className='floor-img floor-sec-divider' onClick={() => setShow(true)} style={{ backgroundImage: `url(${FloorPlanBanner[0]})` }}>
                        </div>
                    </div>
                </div>
            </section>

            <div>

                <Modal
                    className='lg-down'
                    show={show}
                    onHide={() => setShow(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    fullscreen={true}
                >
                    <Modal.Header closeButton>

                    </Modal.Header>
                    <Modal.Body>
                        <div className='d-flex justify-content-center align-items-center'>
                            <img src={FloorPlanBanner[0]} className="img-fluid" />
                        </div>
                    </Modal.Body>
                </Modal>

            </div>

        </>
    )
}
export default Floor
