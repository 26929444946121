import React, { createContext, useState } from 'react';
import './App.css';
import Header from './components/common/Header/Header';
import { companyData } from './constant/companyData';
import Home from './pages/App/Home/index'
import './assets/css/common.css'
import { Navigate, Route, Routes } from 'react-router';
const MyContext = createContext({});

function App() {
  const [nav, setNav] = useState('')
  console.log("companyDatacompanyData", companyData)
  return (
    <>
      <Routes>

        {companyData &&
          Object.keys(companyData).map((companyId: string, index: number) => (
            <Route
            key={index} // Adding a unique key for each route
              path={`${companyId}`}
              element={<Home />} // Assuming Home component takes companyId as a prop
            />
          ))}
        <Route
          path="/"
          element={<Home />}
        />

      </Routes>
    </>
  );
}

export default App;
export { MyContext }