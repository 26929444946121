import React from 'react'
import Header from '../../../components/common/Header/Header'
// import Carousal from '../../../components/common/Carousal/Carousal'
import Address from '../../../components/common/Address/Address'
import FloorPlan from '../../../components/common/FloorPlan/Floor'
import Virtual from '../../../components/common/VirtualTour/Virtual'
import Footer from '../../../components/common/Footer/Footer'
import Slider from '../../../components/common/Slider'
// import Images from '../../../assets/Images';
import './Home.css'

// const { FloorImage, } = Images

const Index = () => {
    return (
        <div className=''>
            <Header/>
            <Slider/>
            {/* <Carousal /> */}
            <Address/>
            <FloorPlan/>
            <Virtual/>
            <Footer/>
        </div>
    )
}
export default Index
