import React, { useState, useContext, useEffect } from 'react'
import './Footer.css'


const Footer = () => {

  return (
    <>

      <div className='footer-bg'>
        <div className='bg-light py-3 text-center'>
          <h1 className='mb-0 fs-16 fw-600 text-dark '>Powered by PropBook</h1>
        </div>
      </div >

    </>

  )
}

export default Footer