export const companyData = {
    "property1": {
        "Banner": [
            "https://i.ibb.co/x6SL6Vf/Whats-App-Image-2023-11-20-at-6-38-39-PM-3.jpg",
            "https://i.ibb.co/LPPMN4F/Whats-App-Image-2023-11-20-at-6-38-39-PM-1.jpg",
            "https://i.ibb.co/tQS63xR/Whats-App-Image-2023-11-20-at-6-38-39-PM.jpg",
            "https://i.ibb.co/vXPzrRX/Whats-App-Image-2023-11-20-at-6-38-39-PM-2.jpg",
            "https://i.ibb.co/YdHvb5d/Whats-App-Image-2023-11-20-at-6-38-40-PM-2.jpg",
            "https://i.ibb.co/N9JzJQB/Whats-App-Image-2023-11-20-at-6-38-40-PM-4.jpg",
            "https://i.ibb.co/QdBD17k/Whats-App-Image-2023-11-20-at-6-38-40-PM.jpg",
            "https://i.ibb.co/TvrSLJm/Whats-App-Image-2023-11-20-at-6-38-40-PM-1.jpg",
        ],
        "Heading": "Residential apartment for rent in Bluewaters Island",
        "Address": "Unit 208, Building 5 Bluewaters Island",
        "Size": "135 sqm / 1453.13 sqft",
        "Condition": "Fitted",
        "OccupancyStatus": "Tenanted",
        "Available": "21/11/2023",
        "Price": "$11,000.0 Per Year",
        "Para": "$2,270 per/sqm",
        "SocialMedia": [
            {
                "whatsapp": "https://whatsapp.com/john_doe",
                "gmail": "mattjabbar2310@gmail.com",
                "phone": "+91 55221 14411"
            }
        ],
        "FloorHeading": "Floor Plan",
        "FloorPlanBanner": [
            "https://i.ibb.co/yQ7NztG/Whats-App-Image-2023-11-20-at-6-32-44-PM-1.jpg",
        ],

        "VirtualHeading": "Virtual Tour",
        "CollectionLink": "https://mls.kuu.la/share/collection/7X0Vf?fs=1&vr=1&sd=1&initload=0&thumbs=1"
        // "CollectionLink": "https://mls.kuu.la/share/collection/7JDJG?fs=1&vr=1&sd=1&initload=0&thumbs=1"
    },


    "property2": {
        "Banner": [
            "https://i.imgur.com/nTr49nU.jpg",
            "https://i.imgur.com/BKwUMJF.jpg",
            "https://i.imgur.com/Kwcb1AX.jpg",
            "https://i.imgur.com/0GiNsId.jpg",
            "https://i.imgur.com/4LGu15N.jpg",
            "https://i.imgur.com/pQaaLIk.jpg",
            "https://i.imgur.com/VFDLnTf.jpg",
            "https://i.imgur.com/VCGbbuA.jpg",
            "https://i.imgur.com/ArWXiXP.jpg",
            "https://i.imgur.com/wmhnPvC.jpg",
        ],
        "Heading": "Office for rent in Dubai Internet City",
        "Address": "Unit 311, Building 2, Dubai Internet City, Dubai",
        "Size": "63 sqm / 678.126 sqft",
        "Condition": "Shell & Core",
        "OccupancyStatus": "Vacant",
        "Available": "01/12/2023",
        "Price": "AED 95,000 per annum",
        "Para": "AED 1,508 per/sqm",
        "SocialMedia": [
            {
                "whatsapp": "https://whatsapp.com/john_doe",
                "gmail": "https://gmail.com/johndoe",
                "phone": "+91 55221 14411",
            }
        ],
        "FloorHeading": "Floor Plan",
        "FloorPlanBanner": [
            "https://i.imgur.com/zq1BuAD.jpg"
        ],

        "VirtualHeading": "Virtual Tour",
        "CollectionLink": "https://kuula.co/share/collection/7X13z?logo=1&info=1&fs=1&vr=0&sd=1&thumbs=1",
    }
}