import React from 'react';
import './Address.css';
import { companyData } from '../../../constant/companyData';
import Images from '../../../assets/Images';

const { Whatsapp, Mail, Phone } = Images;

const Address = () => {
  // Assuming companyData.property1 contains the JSON data
  // const { Heading, Address, Size, Condition, OccupancyStatus, Available, Price, Para, } = companyData.property1;
  const data: any = companyData
  let location: any = window.location.pathname
  location = location.substring(1)
  const { Heading, Address, Size, Condition, OccupancyStatus, Available, Price, Para, }: any =  (location === null || location === undefined || location==='' ) ? data.property1 : data[location] 
  // console.log("location1",data[location])

  return (
    <>

      <div id="address-sec" className='destination-scroll'></div>
      <section className='container sec-divider '>
        <div className=''>
          <div className='row align-items-center'>
            <div className='col-md-8'>
              <div className='details'>
                <h3 className='text-brown fs-22 fw-800 mb-4 mobile-font-22'>{Heading}</h3>
                <div className='d-block d-md-flex gap-4 align-items-center mb-3'>
                  <h5 className='mb-0 fs-16 fw-600 color-black add-width'>Address :</h5>
                  <h5 className='mb-0 fs-16 fw-500 mt-2 mt-md-0'>{Address}</h5>
                </div>

                <div className='d-block d-md-flex gap-4 align-items-center mb-3'>
                  <h5 className='mb-0 fs-16 fw-600 color-black add-width'>Size :</h5>
                  <h5 className='mb-0 fs-16 fw-500 mt-2 mt-md-0'>{Size}</h5>
                </div>

                <div className='d-block d-md-flex gap-4 align-items-center mb-3'>
                  <h5 className='mb-0 fs-16 fw-600 color-black add-width'>Condition :</h5>
                  <h5 className='mb-0 fs-16 fw-500 mt-2 mt-md-0'>{Condition}</h5>
                </div>

                <div className='d-block d-md-flex gap-4 align-items-center mb-3'>
                  <h5 className='mb-0 fs-16 fw-600 color-black add-width'>Occupancy Status :</h5>
                  <h5 className='mb-0 fs-16 fw-500 mt-2 mt-md-0'>{OccupancyStatus}</h5>
                </div>

                <div className='d-block d-md-flex gap-4 align-items-center'>
                  <h5 className='mb-0 fs-16 fw-600 color-black add-width'>Available From :</h5>
                  <h5 className='mb-0 fs-16 fw-500 mt-2 mt-md-0'>{Available}</h5>
                </div>
              </div>
            </div>

            <div className='col-md-4 mt-4 mt-md-0'>
              <div className='info-sec'>
                <h5 className='mb-0 fs-20 fw-800 text-center text-brown '>{Price}</h5>
                <p className='mb-0 fs-12 mt-2 fw-700 text-center '>{Para}</p>

                <div className='d-flex gap-3 justify-content-center align-items-center mt-3'>
                  <a href='https://wa.me/+971585908990'>
                    <img
                      src={Whatsapp}
                      alt=''
                      className='img-fluid'
                      style={{ width: '70px', height: '70px', cursor: 'pointer' }}
                    />
                  </a>
                  <a href='mailto:mattjabbar2310@gmail.com'>
                    <img
                      src={Mail}
                      alt=''
                      className='img-fluid'
                      style={{ width: '70px', height: '70px', cursor: 'pointer' }}
                    />
                  </a>
                  <a href='tel:+971585908990'>
                    <img
                      src={Phone}
                      alt=''
                      className='img-fluid'
                      style={{ width: '70px', height: '70px', cursor: 'pointer' }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Address;